<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex justify-content-between align-items-center">
              <div class="todo-date d-flex mr-3">
                <i :class="`ri-calendar-2-line text-info mr-2`"></i>
                <span class="h5">{{ $t("sidebar.blog") }}</span>
              </div>

              <b-button
                variant="primary"
                @click="$refs.modalBlogCreate.show()"
                >{{ $t("app.new") }}</b-button
              >
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-overlay :show="blogLoading" rounded="sm">
      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-col sm="12" md="2" class="pr-1 mb-2">
              <b-form-select
                :options="['5', '10', '20', '50', '100']"
                v-model="filter.per_page"
                @change="getData()"
              ></b-form-select>
            </b-col>
            <b-col lg="8" md="8" sm="12" class="ml-auto mb-2">
              <div class="d-flex align-items-center flex-column flex-sm-row">
                <v-select
                  v-model="filter.category"
                  :placeholder="$t('categories.category')"
                  :options="getCategories"
                  :reduce="(category) => category.id"
                  label="name"
                  class="w-50 mx-3"
                  @input="getData()"
                >
                  <template #no-options>
                    {{ $t("app.not-found") }}...
                  </template>
                </v-select>
                <b-form-input
                  class="border w-50"
                  style="height: 35px"
                  id="filter-input"
                  v-model="filter.search"
                  type="search"
                  :placeholder="$t('app.search')"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>

          <div class="table-responsive">
            <b-table
              class="table mb-0 table-borderless"
              :items="getPost.data"
              :fields="columns"
            >
              <template v-slot:cell(image)="data">
                <b-avatar
                  :src="data.item.image"
                  size="3rem"
                  rounded
                  class="bg-transparent"
                ></b-avatar>
              </template>
              <template v-slot:cell(category)="{ item }">
                <span>
                  {{
                    item.category
                      ? item.category.name
                      : $t("categories.not-category")
                  }}
                </span>
              </template>
              <template v-slot:cell(created_at)="data">
                {{ moment(data.item.created_at).format("DD-MM-YYYY HH:mm") }}
                hrs.
              </template>
              <template v-slot:cell(action)="data">
                <b-button
                  variant=" iq-bg-warning rounded"
                  class="mr-1"
                  size="sm"
                  @click="$refs.modalBlogCreate.show(data.item)"
                  ><i class="ri-ball-pen-fill ml-1"></i
                ></b-button>
                <b-button
                  variant=" iq-bg-primary rounded"
                  class="mx-1"
                  size="sm"
                  @click="showCurrentPost(data.item)"
                  ><i class="ri-eye-fill ml-1"></i
                ></b-button>
                <b-button
                  @click="deleteItem(data.item.id)"
                  variant=" iq-bg-danger rounded"
                  size="sm"
                  ><i class="ri-delete-bin-line ml-1"></i
                ></b-button>
                <b-button
                  variant=" iq-bg-primary rounded"
                  class="mx-1"
                  size="sm"
                  @click="$refs.modalBlogGallery.show(data.item)"
                  ><i class="ri-image-fill ml-1"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              class="mt-3 float-right"
              v-model="filter.page"
              :total-rows="getPost.total"
              :per-page="getPost.per_page"
              first-number
              pills
              size="sm"
              @change="getData"
            ></b-pagination>
            <p class="mt-3">
              {{ $t("app.registers") }} {{ getPost.to }} {{ $t("app.of") }}
              {{ getPost.total }}. Total
              {{ getPost.total }}
            </p>
          </div>
        </template>
      </iq-card>
    </b-overlay>

    <!-- Modal Form -->
    <modal-form-blog ref="modalBlogCreate" @refresh="getData()" />

    <!-- Modal Gallery -->
    <modal-show-gallery-post ref="modalBlogGallery" />

    <b-modal
      size="lg"
      hide-footer
      id="bv-modal-show-post"
      :title="$t('blog.show')"
    >
      <section v-if="currentPost">
        <b-row>
          <b-col md="6" sm="12">
            <h6>{{ $t("blog.title") }}</h6>
            <p class="mt-1">
              {{ currentPost.title }}
            </p>
            <h6>{{ $t("blog.created_at") }}</h6>
            <p class="mt-1">
              {{ moment(currentPost.created_at).format("DD-MM-YYYY HH:mm") }}
            </p>
            <h6>{{ $t("categories.category") }}</h6>
            <p class="mt-1">
              {{
                currentPost.category
                  ? currentPost.category.name
                  : $t("categories.not-category")
              }}
            </p>
          </b-col>
          <b-col md="6" sm="12">
            <div>
              <b-img
                class="rounded"
                id="pick-image"
                style="max-width: 300px"
                :src="
                  currentPost.image
                    ? currentPost.image
                    : require('@/assets/images/logo/placeholder.png')
                "
              ></b-img>
            </div>
          </b-col>
        </b-row>
        <h6>{{ $t("blog.content") }}</h6>
        <p class="mt-1" v-html="currentPost.content"></p>
      </section>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalFormBlog from "./Components/CreatedOrUpdate.vue";
import modalShowGalleryPost from "./Components/ModalShowGalleryPost.vue";
import { mapActions, mapGetters } from "vuex";
import SocialPost from "./Components/SocialPost.vue";
import _ from "lodash";
import moment from "moment";
export default {
  name: "Blog",
  components: {
    modalFormBlog,
    SocialPost,
    modalShowGalleryPost,
  },
  async mounted() {
    core.index();
    this.debouncedGetPosts = _.debounce(this.getData, 500);
    await this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetPosts();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchBlogCollege: "fetchBlogCollege",
      deleteBlog: "deleteBlog",
    }),
    showCurrentPost(post) {
      this.currentPost = post;
      this.$bvModal.show("bv-modal-show-post");
    },
    updateItem(data) {
      this.$refs.modalBlogCreate.show(data);
    },
    async getData(page = 1) {
      this.filter.page = page;
      await this.fetchBlogCollege(this.filter);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteBlog(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      filter: {
        paginate: true,
        category: null,
        page: 1,
        per_page: 10,
        search: null,
      },
      totalRows: 1,
      typeView: false,
      moment,
      currentPost: null,
    };
  },
  computed: {
    ...mapGetters({
      getPost: "getPost",
      getCategories: "getCategories",
      blogLoading: "blogLoading",
    }),
    columns() {
      return [
        { label: "", key: "image", class: "text-center" },
        {
          label: this.$t("blog.title"),
          key: "title",
          class: "text-center",
        },
        {
          label: this.$t("categories.category"),
          key: "category",
          class: "text-center",
        },
        {
          label: this.$t("blog.created_at"),
          key: "created_at",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
<style>
.content-width {
  max-width: 460px !important;
}
</style>

<template>
  <b-modal
    size="lg"
    hide-footer
    id="bv-modal-show-gallery"
    :title="`${$t('blog.show-gallery')} ${
      currentPost ? currentPost.title : ''
    }`"
  >
    <div class="mb-4" style="cursor: pointer">
      <b-button variant="primary" class="" id="pick-image-gallery">{{
        $t("app.new-image")
      }}</b-button>
      <avatar-cropper
        :upload-handler="selectedFile"
        @changed="setFileName"
        trigger="#pick-image-gallery"
        :labels="{
          submit: $t('app.accept'),
          cancel: $t('app.cancel'),
        }"
        :cropper-options="{
          autoCropArea: 1,
          viewMode: 0,
          movable: true,
          zoomable: false,
        }"
      />
    </div>
    <section v-if="getPostGallery.length">
      <b-row align-h="start">
        <b-col md="4" sm="12" v-for="image in getPostGallery" :key="image.id">
          <b-img
            class=""
            thumbnail
            fluid
            :src="
              image.url
                ? image.url
                : require('@/assets/images/logo/placeholder.png')
            "
            alt="Image Gallery"
          >
          </b-img>
          <b-button
            @click="deleteItem(image.id)"
            class="close-button"
            variant="danger"
            size="sm"
            ><i style="padding-left: 0.4rem" class="ri-close-fill"></i
          ></b-button>
        </b-col>
      </b-row>
    </section>
    <p class="mt-1 text-center" v-else>
      {{ $t("app.not-found") }}
    </p>
  </b-modal>
</template>

<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "ModalShowGalleryPost",
  data() {
    return {
      currentPost: null,
      fileSelected: null,
      image: null,
    };
  },
  mounted() {
    core.index();
  },
  components: {
    AvatarCropper,
  },
  methods: {
    ...mapActions({
      addImageGallery: "addImageGallery",
      fetchBlogGallery: "fetchBlogGallery",
      deleteImageGallery: "deleteImageGallery",
    }),
    setFileName(file) {
      this.fileSelected = file;
      this.image = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.image = file;
      const response = await this.addImageGallery({
        image: this.image,
        id: this.currentPost.id,
      });
      if (response.status === 200) {
        core.showSnackbar("success", this.$t("app.success-image"));
        await this.fetchBlogGallery({ id: this.currentPost.id });
      }
    },
    async show(post) {
      this.currentPost = post;
      await this.fetchBlogGallery({ id: post.id });
      this.$bvModal.show("bv-modal-show-gallery");
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteImageGallery(id);
            await this.fetchBlogGallery({ id: this.currentPost.id });
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      getPostGallery: "getPostGallery",
    }),
  },
};
</script>

<style>
.close-button {
  padding: 0px !important;
  margin-top: -20rem;
  border-radius: 100%;
}
</style>

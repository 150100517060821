<template>
  <iq-card body-class="p-0">
    <template v-slot:body>
      <div class="user-post-data p-3">
        <b-button
          @click="deleteItem(post.id)"
          variant="outline-danger"
          class="float-right"
          >{{ $t("app.delete") }}</b-button
        >
        <b-button
          variant="outline-success"
          class="float-right mr-1"
          @click="$emit('update', post)"
          >{{ $t("app.edit") }}</b-button
        >
        <div class="d-flex flex-wrap">
          <div class="media-support-user-img mr-3">
            <b-img
              rounded="circle"
              fluid
              :src="
                post.author.avatar ||
                require('@/assets/images/logo/placeholder.png')
              "
              alt=""
            />
          </div>
          <div class="media-support-info mt-2">
            <h5 class="mb-0">
              <b-link href="#" class="">{{ post.author.name }}</b-link>
            </h5>
            <p class="mb-0 text-secondary">
              {{ moment(post.created_at).format("DD-MM-YYYY HH:mm") }} hrs.
            </p>
          </div>
        </div>
      </div>
    </template>
    <hr class="m-0" />
    <div class="user-post">
      <p class="p-2" v-if="post.content">{{ post.content }}</p>
      <div id="photo-grid" class="p1">
        <photo-grid :box-height="'400px'" :box-width="'100%'" :boxBorder="2">
          <img :src="post.image" />
        </photo-grid>
      </div>
    </div>
  </iq-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { core } from "@/config/pluginInit";

export default {
  name: "SocialPost",
  props: {
    post: {
      type: Object,
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    ...mapActions({
      deleteBlog: "deleteBlog",
    }),
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
         okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteBlog(id);
            if (resp.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

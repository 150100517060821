<template>
  <b-modal id="modal-blog" hide-footer size="lg">
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("blog.new") : $t("blog.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row align-h="between">
            <b-col md="12">
              <div class="text-center mb-4" style="cursor: pointer">
                <b-img
                  class="mt-2"
                  id="pick-image"
                  style="max-width: 300px"
                  :src="
                    preview
                      ? preview
                      : require('@/assets/images/logo/placeholder.png')
                  "
                ></b-img>

                <avatar-cropper
                  :upload-handler="selectedFile"
                  @changed="setFileName"
                  trigger="#pick-image"
                  :labels="{
                    submit: $t('app.accept'),
                    cancel: $t('app.cancel'),
                  }"
                  :cropper-options="{
                    aspectRatio: 0,
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: true,
                    zoomable: true,
                  }"
                />
              </div>
            </b-col>
            <b-col md="12">
              <b-row align-v="center">
                <b-form-group
                  class="col-md-12"
                  :label="$t('blog.scope')"
                  label-for="scope"
                >
                  <ValidationProvider
                    vid="scope"
                    :name="$t('blog.scope')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="data.type_model_post"
                      :placeholder="$t('blog.scope')"
                      :options="scopeOptions"
                      :reduce="(item) => item.id"
                      label="name"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #no-options="{}">
                        {{ $t("app.not-found") }}...
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  :label="$t('sidebar.grades')"
                  label-for="grades"
                  v-if="data.type_model_post === SCOPE_COLLEGE_OPTION_GRADE"
                >
                  <ValidationProvider
                    vid="grades"
                    :name="$t('sidebar.grades')"
                    :rules="
                      data.type_model_post === SCOPE_COLLEGE_OPTION_GRADE
                        ? 'required'
                        : ''
                    "
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="data.grades"
                      :placeholder="$t('sidebar.grades')"
                      :options="getGrades"
                      label="name"
                      multiple
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #selected-option="{ name, typeEducation }">
                        <div>
                          {{ `${typeEducation.name} - ${name}` }}
                        </div>
                      </template>
                      <template #option="{ name, typeEducation }">
                        <div>
                          {{ `${typeEducation.name} - ${name}` }}
                          <br />
                        </div>
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  :label="$t('sidebar.courses')"
                  label-for="courses"
                  v-if="data.type_model_post === SCOPE_COLLEGE_OPTION_COURSE"
                >
                  <ValidationProvider
                    vid="courses"
                    :name="$t('sidebar.courses')"
                    :rules="
                      data.type_model_post === SCOPE_COLLEGE_OPTION_COURSE
                        ? 'required'
                        : ''
                    "
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="data.courses"
                      :placeholder="$t('app.min-search')"
                      :options="getCourses"
                      @search="(query) => getDebouncedCourses(query)"
                      label="name"
                      multiple
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template
                        #selected-option="{ grade, name, schoolPeriod }"
                      >
                        <div>
                          {{ `${grade.name} - ${name}` }}
                        </div>
                        <br />
                        <cite>{{ schoolPeriod.name }}</cite>
                      </template>
                      <template #option="{ grade, name, schoolPeriod }">
                        <div>
                          {{ `${grade.name} - ${name}` }}
                          <br />
                          <cite>{{ schoolPeriod.name }}</cite>
                        </div>
                      </template>
                      <template #no-options="{ searching }">
                        {{
                          searching ? $t("app.not-found") : $t("app.min-search")
                        }}
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <!-- NAME -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('blog.title')"
                  label-for="title"
                >
                  <ValidationProvider
                    :name="$t('blog.title')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="data.title"
                      type="text"
                      :placeholder="$t('blog.title-placeholder')"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <!-- CATEGORY -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('categories.category')"
                  label-for="category"
                >
                  <ValidationProvider
                    :name="$t('categories.category')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="data.category"
                      :placeholder="$t('categories.category')"
                      :options="getCategories"
                      label="name"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #no-options="{}">
                        {{ $t("app.not-found") }}...
                      </template>
                    </v-select>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <!-- DESCRIPTION -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('blog.content')"
                  label-for="content"
                >
                  <ValidationProvider
                    :name="$t('blog.content')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <ckeditor
                      :editor="editor"
                      v-model="data.content"
                      :config="editorConfig"
                      :placeholder="$t('blog.content-placeholder')"
                      rows="3"
                      max-rows="6"
                    ></ckeditor>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="blogLoading"
            variant="success"
            type="submit"
          >
            <span v-if="blogLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-blog')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  SCOPE_COLLEGE_OPTIONS,
  SCOPE_COLLEGE_OPTION_COURSE,
  SCOPE_COLLEGE_OPTION_ALL_COLLEGE,
  SCOPE_COLLEGE_OPTION_TEACHERS_COLLEGE,
  SCOPE_COLLEGE_OPTION_ADMINISTRATIVES_COLLEGE,
  SCOPE_COLLEGE_OPTION_STAFF_COLLEGE,
  SCOPE_COLLEGE_OPTION_GRADE,
  SCOPE_COLLEGE_OPTION_ATTORNEYS_COLLEGE,
  SCOPE_COLLEGE_OPTION_DEVELOPERS,
} from "@/constants/college";
import {
  ROLE_TEACHER_ID,
  ROLE_ATTORNEY_ID,
  ROLE_ADMINISTRATIVE_ID,
  ROLE_DEVELOPER_ID,
} from "@/constants/roles";
export default {
  name: "modalForm",
  components: {
    AvatarCropper,
  },
  created() {
    core.index();
    this.cleanListCourses();
    this.fetchCategories();
    this.fetchGrades();
  },
  data() {
    return {
      loading: false,
      numberOfRolesStaffCollege: 3,
      fileSelected: null,
      preview: "",
      data: {
        id: null,
        type_model_post: null,
        courses: [],
        roles: [],
        grades: [],
        title: null,
        content: null,
        category: null,
        image: null,
      },
      scopeOptions: SCOPE_COLLEGE_OPTIONS,
      SCOPE_COLLEGE_OPTION_COURSE,
      SCOPE_COLLEGE_OPTION_ALL_COLLEGE,
      SCOPE_COLLEGE_OPTION_GRADE,
      courses: {
        debounce: null,
        debounceTime: 300,
      },
      //WYSWYF Editor
      editor: ClassicEditor,
      editorConfig: {
        language: { content: "es", ui: "es" },
        link: {
          addTargetToExternalLinks: true,
        },
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "storeBlog",
      fetchCourses: "fetchCourses",
      cleanListCourses: "cleanListCourses",
      fetchCategories: "fetchCategories",
      fetchGrades: "fetchGrades",
    }),
    getTypeModelPostFromPost(post) {
      return post.courses.length
        ? SCOPE_COLLEGE_OPTION_COURSE
        : post.roles.length >= this.numberOfRolesStaffCollege
        ? SCOPE_COLLEGE_OPTION_STAFF_COLLEGE
        : post.roles.find((role) => role.id === ROLE_TEACHER_ID)
        ? SCOPE_COLLEGE_OPTION_TEACHERS_COLLEGE
        : post.roles.find((role) => role.id === ROLE_ADMINISTRATIVE_ID)
        ? SCOPE_COLLEGE_OPTION_ADMINISTRATIVES_COLLEGE
        : post.roles.find((role) => role.id === ROLE_ATTORNEY_ID)
        ? SCOPE_COLLEGE_OPTION_ATTORNEYS_COLLEGE
        : post.roles.find((role) => role.id === ROLE_DEVELOPER_ID)
        ? SCOPE_COLLEGE_OPTION_DEVELOPERS
        : SCOPE_COLLEGE_OPTION_ALL_COLLEGE;
    },
    async show(item) {
      this.reserForm();
      if (item) {
        this.preview = item.image;
        this.data = {
          id: item.id,
          type_model_post: this.getTypeModelPostFromPost(item),
          courses: item.courses,
          roles: [],
          grades: [],
          title: item.title,
          content: item.content,
          category: item.category ? item.category : null,
        };
      }
      this.$bvModal.show("modal-blog");
      setTimeout(() => {
        document
          .getElementById("modal-blog___BV_modal_content_")
          .removeAttribute("tabindex");
      }, 1000);
    },
    setFileName(file) {
      this.fileSelected = file;
      this.data.image = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.data.image = file;
    },
    getRolesFromTypeModelPost() {
      return this.data.type_model_post === SCOPE_COLLEGE_OPTION_TEACHERS_COLLEGE
        ? [ROLE_TEACHER_ID, ROLE_DEVELOPER_ID]
        : this.data.type_model_post ===
          SCOPE_COLLEGE_OPTION_ADMINISTRATIVES_COLLEGE
        ? [ROLE_ADMINISTRATIVE_ID, ROLE_DEVELOPER_ID]
        : this.data.type_model_post === SCOPE_COLLEGE_OPTION_ATTORNEYS_COLLEGE
        ? [ROLE_ATTORNEY_ID, ROLE_DEVELOPER_ID]
        : this.data.type_model_post === SCOPE_COLLEGE_OPTION_STAFF_COLLEGE
        ? [ROLE_ADMINISTRATIVE_ID, ROLE_TEACHER_ID, ROLE_DEVELOPER_ID]
        : this.data.type_model_post === SCOPE_COLLEGE_OPTION_DEVELOPERS
        ? [ROLE_DEVELOPER_ID]
        : [];
    },
    async onSubmit() {
      const resp = await this.saveForm({
        ...this.data,
        courses: this.data.courses.map((course) => course.id),
        grades: this.data.grades.map((grade) => grade.id),
        roles: this.getRolesFromTypeModelPost(),
        category_id: this.data.category.id
          ? this.data.category.id
          : this.data.category,
      });
      this.$bvModal.hide("modal-blog");
      this.$emit("refresh");
      if (resp?.status === 201 || resp?.status === 200) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("blog.success-created")
            : this.$t("blog.success-edit")
        );
      }
      this.reserForm();
    },
    async getDebouncedCourses(search) {
      clearTimeout(this.courses.debounce);
      this.courses.debounce = await setTimeout(async () => {
        if (search.length >= 1) {
          this.courses.loading = true;
          try {
            let paramsSearch = {
              search,
            };
            await this.fetchCourses(paramsSearch);
          } catch (error) {
            return errror;
          } finally {
            this.courses.loading = false;
          }
        }
      }, this.courses.debounceTime);
    },
    reserForm() {
      this.preview = null;
      this.fileSelected = null;
      this.data = {
        id: null,
        title: null,
        content: null,
        type_model_post: null,
        courses: [],
        grades: [],
        roles: [],
        image: null,
        category: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      getCourses: "getCourses",
      getGrades: "getGrades",
      getCategories: "getCategories",
      blogLoading: "blogLoading",
    }),
  },
};
</script>

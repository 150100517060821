export const OPTIONS_APP_STATUS_PUBLISHING = [
  {
    id: 1,
    name: "Pendiente de publicación",
  },
  {
    id: 2,
    name: "Publicada en Google Play",
  },
  {
    id: 3,
    name: "Publicada en IOS",
  },
  {
    id: 4,
    name: "Publicada",
  },
];

export const SCOPE_COLLEGE_OPTIONS = [
  { id: 1, name: "Por curso" },
  { id: 2, name: "Todo el colegio" },
  { id: 3, name: "Todos los profesores del colegio" },
  { id: 4, name: "Todos los administrativos del colegio" },
  { id: 5, name: "Todo el personal del colegio" },
  { id: 6, name: "Por nivel" },
  { id: 7, name: "Todos los apoderados del colegio" },
  { id: 8, name: "Todos los Desarrolladores" },
];

export const SCOPE_COLLEGE_OPTION_COURSE = 1;
export const SCOPE_COLLEGE_OPTION_ALL_COLLEGE = 2;
export const SCOPE_COLLEGE_OPTION_TEACHERS_COLLEGE = 3;
export const SCOPE_COLLEGE_OPTION_ADMINISTRATIVES_COLLEGE = 4;
export const SCOPE_COLLEGE_OPTION_STAFF_COLLEGE = 5;
export const SCOPE_COLLEGE_OPTION_GRADE = 6;
export const SCOPE_COLLEGE_OPTION_ATTORNEYS_COLLEGE = 7;
export const SCOPE_COLLEGE_OPTION_DEVELOPERS = 8;

export const OPTIONS_PLATFORM_MASSIVE_UPLOAD = [
  { id: 1, name: "Netcore" },
  { id: 2, name: "Grange School" },
];

export const OPTION_PLATFORM_MASSIVE_UPLOAD_NETCORE_ID = 1;
export const OPTION_PLATFORM_MASSIVE_UPLOAD_GRANGE_ID = 2;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"size":"lg","hide-footer":"","id":"bv-modal-show-gallery","title":`${_vm.$t('blog.show-gallery')} ${
    _vm.currentPost ? _vm.currentPost.title : ''
  }`}},[_c('div',{staticClass:"mb-4",staticStyle:{"cursor":"pointer"}},[_c('b-button',{attrs:{"variant":"primary","id":"pick-image-gallery"}},[_vm._v(_vm._s(_vm.$t("app.new-image")))]),_c('avatar-cropper',{attrs:{"upload-handler":_vm.selectedFile,"trigger":"#pick-image-gallery","labels":{
        submit: _vm.$t('app.accept'),
        cancel: _vm.$t('app.cancel'),
      },"cropper-options":{
        autoCropArea: 1,
        viewMode: 0,
        movable: true,
        zoomable: false,
      }},on:{"changed":_vm.setFileName}})],1),(_vm.getPostGallery.length)?_c('section',[_c('b-row',{attrs:{"align-h":"start"}},_vm._l((_vm.getPostGallery),function(image){return _c('b-col',{key:image.id,attrs:{"md":"4","sm":"12"}},[_c('b-img',{attrs:{"thumbnail":"","fluid":"","src":image.url
              ? image.url
              : require('@/assets/images/logo/placeholder.png'),"alt":"Image Gallery"}}),_c('b-button',{staticClass:"close-button",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteItem(image.id)}}},[_c('i',{staticClass:"ri-close-fill",staticStyle:{"padding-left":"0.4rem"}})])],1)}),1)],1):_c('p',{staticClass:"mt-1 text-center"},[_vm._v(" "+_vm._s(_vm.$t("app.not-found"))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }